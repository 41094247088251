.oak-editor {
  label {
    font-size: 13px;
  }
  &.bubble {
    border-bottom: 1px solid var(--color-foreground-1);
  }

  .ql-toolbar.ql-snow {
    border: none;
    color: var(--color-foreground-1);
    padding: 0px;
    display: flex;
    justify-content: flex-start;
  }

  &.center {
    .ql-toolbar.ql-snow {
      display: flex;
      justify-content: center;
    }
  }
}

.quill {
  .ql-editor {
    min-height: 200px;
  }
  .ql-syntax {
    border-radius: 4px;
    padding: 4px;
    background-color: var(--color-background-transparent-5);
  }

  blockquote {
    background-color: var(--color-background-transparent-5);
  }

  .ql-formats {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: 0 !important;
  }

  .ql-formats > * {
    margin-right: 4px;
    margin-left: 0px;
    margin-bottom: 12px;
    @media (max-width: 767px) {
      margin-right: 0px;
      margin-left: 0px;
      margin-bottom: 0px;
    }
  }

  .ql-container.ql-snow {
    border: none;
    border-bottom: 1px solid var(--color-foreground-1);
  }
  .ql-editor {
    padding: 10px 0;
    font-size: 16px;
  }
  .ql-snow .ql-stroke {
    stroke: var(--color-foreground-3);
  }
  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: var(--color-foreground-3);
  }
  .ql-picker-label {
    color: var(--color-foreground-3);
    &:hover {
      color: green;
    }
  }
  .ql-strike.ql-active {
    // border: 1px solid var(--color-foreground-3);
    background-color: var(--color-foreground-5);
    color: var(--color-foreground-1);
  }

  h1 {
    font-size: 2em !important;
  }
  h2 {
    font-size: 1.5em !important;
  }
  h3 {
    font-size: 1.17em;
  }
  h4 {
    font-size: 1.12em;
  }
  h5 {
    font-size: 0.83em;
  }
  h6 {
    font-size: 0.75em;
  }

  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background-color: var(--color-background-5);
    color: var(--color-foreground-1);
    .ql-picker-item {
      &:hover {
        color: var(--color-primary-1) !important;
      }
    }
  }

  .ql-formats {
    button {
      height: auto !important;
      width: auto !important;
      &:hover {
        background-color: var(--color-background-3) !important;
        border-radius: 2px;
      }
    }
    .ql-picker-label,
    .ql-picker {
      // height: auto !important;
      // width: auto !important;
    }
    svg {
      height: 20px !important;
    }
    .ql-active {
      // border: 1px solid var(--color-primary-1) !important;
      border-radius: 2px;
      background-color: var(--color-background-5) !important;
      &:hover {
        background-color: var(--color-background-5) !important;
      }
      color: var(--color-foreground-1) !important;
      .ql-fill {
        fill: var(--color-foreground-3) !important;
      }
      .ql-stroke {
        stroke: var(--color-foreground-3) !important;
      }
    }
    &:hover {
      .ql-fill {
        fill: var(--color-foreground-3) !important;
      }
      .ql-stroke {
        stroke: var(--color-foreground-1) !important;
      }
    }
  }
}
