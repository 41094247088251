.oak-text {
  // width: 100%;

  input,
  textarea {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    color: var(--color-formelement-fg);
    font-size: 16px;
    outline: none;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid var(--color-foreground-1);
    &:focus {
      border-bottom: 1px solid var(--color-primary-1);
    }
    box-sizing: border-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    // height: auto;
    // padding: 10px 0;
    height: 42px;

    &.error {
      // border-color: var(--color-failure);
      background-color: var(--color-failure-dim);

      &:focus {
        color: var(--color-foreground-1);
      }
    }

    &.disabled {
      background-color: var(--color-formelement-bg-2);
      color: var(--color-foreground-4);
    }
  }

  textarea {
    height: 100px;
    overflow-y: auto;
    resize: vertical;
    // max-height: 200px;
    padding: 10px 0;
  }
  // margin-top: 1.5rem;
  // margin-bottom: 1.5rem;
  margin-top: 16px;
  margin-bottom: 16px;
  text-align: left;
  position: relative;
  label {
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    // font-size: 1rem;
    cursor: text;
    top: 0;
    left: 0;
    color: #757575;
    display: inline-block;
    margin-bottom: -0.5rem;
    transition: transform 0.2s ease-out, color 0.2s ease-out,
      -webkit-transform 0.2s ease-out;
    transform-origin: 0 100%;
    padding: 10px 0;
    pointer-events: none;

    &.active {
      color: var(--color-foreground-3);
      // font-size: 1rem;
      transform: translateY(-26px) scale(0.8);
      padding: 10px 0;
    }
  }

  input:focus + label.active,
  textarea:focus + label.active {
    color: var(--color-primary-1);
  }
}
