.oak-spinner,
.spinner-wrapper {
  z-index: 999;
  position: relative;
  // top: 50%;
  // left: 25%;
  // transform: translate(-50%, -50%);
  // transform: translateX(-50%);

  :nth-child(1) {
    animation-delay: 500ms;
  }
}

.oak-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: 10px 0;
  // width: 100%;
  .spinner-wrapper {
    display: flex;

    span {
      background-color: var(--color-primary-1);
      background-color: white;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      position: relative;
      display: inline-block;
      margin: 0 2px;
      animation: loading 2500ms ease-in-out infinite;

      &:nth-child(5) {
        animation-delay: 100ms;
      }
      &:nth-child(4) {
        animation-delay: 200ms;
      }
      &:nth-child(3) {
        animation-delay: 300ms;
      }
      &:nth-child(2) {
        animation-delay: 400ms;
      }
      &:nth-child(1) {
        animation-delay: 500ms;
      }
    }
  }
}

@keyframes loading {
  0% {
    transform: translateX(-30vw);
    opacity: 0;
  }
  35%,
  65% {
    transform: translateX(0vw);
    opacity: 1;
  }
  100% {
    transform: translateX(+30vw);
    opacity: 0;
  }
}

//   @keyframes move-out-2 {
//       0% {
//         transform: translateX(-100vw);
//           opacity: 0;
//       }
//       100% {
//         transform: translateX(+50vw);
//           opacity: 1;
//       }
//     }

//     @keyframes move-out-3 {
//         0% {
//             transform: translateX(-100vw);
//             opacity: 0;
//         }
//         100% {
//             transform: translateX(+50vw);
//             opacity: 1;
//         }
//       }
