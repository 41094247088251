@import url('https://fonts.googleapis.com/css?family=Roboto|Montserrat|Lato|Open+Sans|Open+Sans+Condensed:300|Source+Sans+Pro&display=swap');

* {
  margin: 0;
  padding: 0;

  --color-tag-1: #904e55;
  --color-tag-2: #e26d5c;
  --color-tag-3: #b68cb8;
  --color-tag-4: #0a81d1;
  --color-tag-5: #537a5a;
  --color-tag-6: #0c8346;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .MuiInputBase-root,
  .MuiTypography-root {
    color: var(--color-foreground-1);
  }
  .MuiInputLabel-root {
    color: var(--color-foreground-2);
  }
  // overflow-y: overlay;
}

body.oak-dialog-open {
  // height: 100vh;
  overflow-y: hidden;
  padding-right: var(--scrollbar-width);
}

.body {
  font-family: 'Open Sans', sans-serif;
  background-color: var(--color-background-1);
  color: var(--color-foreground-1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// 34ba9b
// f68c70

:root {
  --color-pick: #4b5db3;
  // --color-pick: #7A93DC;
  --height-nav: 50px;

  --color-themecolor-1: #69a7bf;
  --color-themecolor-2: #99587b;
  --color-themecolor-3: #a66c26;
  --color-themecolor-4: #37ae82;
  // --bs-sm: 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.05),
  //   0 2px 8px rgba(0, 0, 0, 0.05);
  --bs-sm: 0 1px 3px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.06),
    0 3px 8px rgba(0, 0, 0, 0.09);
  --bs-md: 0 1px 4px rgba(0, 0, 0, 0.09), 0 3px 8px rgba(0, 0, 0, 0.09),
    0 4px 13px rgba(0, 0, 0, 0.13);
  // --bs-xl: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  // --bs-xl: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  --bs-lg: 0 10px 20px -12px rgba(0, 0, 0, 0.42),
    0 3px 20px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  --bs-xl: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);

  .themecolor1 {
    --color-dark-primary-1: #62abbd;
    --color-dark-primary-2: #62abbd;
    --color-dark-secondary-1: #7bb38e; // 69a7bf
    --color-dark-secondary-2: #7bb38e;
    --color-dark-tertiary-1: #94999c;
    --color-dark-tertiary-2: rgba(55, 107, 140, 0.2);
    --color-dark-default-1: #5a5766;
    --color-dark-default-2: #7b7885;
    --color-dark-background-theme: #c29265;
    --color-light-primary-1: #4e8c9d;
    --color-light-primary-2: #4e8c9d;
    --color-light-secondary-1: #7bb38e;
    --color-light-secondary-2: #7bb38e;
    --color-light-tertiary-1: #91ccd9;
    --color-light-tertiary-2: rgba(145, 204, 217, 0.2);
    --color-light-default-1: #5a5766;
    --color-light-default-2: #7b7885;
    --color-light-background-theme: #b3835b;
  }

  .themecolor2 {
    --color-dark-primary-1: #ba7799;
    --color-dark-secondary-1: #99587b;
    --color-dark-tertiary-1: #753959;
    --color-dark-tertiary-1-trans: rgba(117, 57, 89, 0.2);
    --color-light-primary-1: #753959;
    --color-light-secondary-1: #99587b;
    --color-light-tertiary-1: #ba7799;
    --color-light-tertiary-1-trans: rgba(186, 119, 153, 0.2);
  }

  .themecolor3 {
    --color-dark-primary-1: #d99e30;
    --color-dark-secondary-1: #a66c26;
    --color-dark-tertiary-1: #734822;
    --color-dark-tertiary-1-trans: rgba(115, 72, 34, 0.2);
    --color-light-primary-1: #734822;
    --color-light-secondary-1: #a66c26;
    --color-light-tertiary-1: #d99e30;
    --color-light-tertiary-1-trans: rgba(217, 158, 48, 0.2);
  }

  .themecolor4 {
    --color-dark-primary-1: #82d9b0;
    --color-dark-secondary-1: #37ae82;
    --color-dark-tertiary-1: #1e8861;
    --color-dark-tertiary-1-trans: rgba(30, 136, 97, 0.1);
    --color-light-primary-1: #1e8861;
    --color-light-secondary-1: #37ae82;
    --color-light-tertiary-1: #82d9b0;
    --color-light-tertiary-1-trans: rgba(130, 217, 176, 0.1);
  }

  .theme_dark {
    --color-background-transparent-1: rgba(0, 0, 0, 0.1);
    --color-background-transparent-2: rgba(0, 0, 0, 0.2);
    --color-background-transparent-3: rgba(0, 0, 0, 0.3);
    --color-background-transparent-4: rgba(0, 0, 0, 0.4);
    --color-background-transparent-5: rgba(0, 0, 0, 0.5);
    --color-background-transparent-6: rgba(0, 0, 0, 0.6);
    --color-background-transparent-7: rgba(0, 0, 0, 0.7);
    --color-background-transparent-8: rgba(0, 0, 0, 0.8);
    --color-background-transparent-9: rgba(0, 0, 0, 0.9);

    --color-background-1: #202225;
    --color-background-2: #292b2f;
    --color-background-3: #2f3136;
    --color-background-4: #36393f;
    --color-background-5: #53575d;
    --color-background-theme: var(--color-dark-background-theme);

    --color-foreground-1: #ffffff;
    --color-foreground-2: #f5f5f5;
    --color-foreground-3: #f0f0f0;
    --color-foreground-4: rgba(255, 255, 255, 0.6);
    --color-foreground-5: rgba(255, 255, 255, 0.5);

    --color-primary-1: var(--color-dark-primary-1);
    --color-primary-2: var(--color-dark-primary-2);
    --color-secondary-1: var(--color-dark-secondary-1);
    --color-secondary-2: var(--color-dark-secondary-2);
    --color-tertiary-1: var(--color-dark-tertiary-1);
    --color-tertiary-2: var(--color-dark-tertiary-2);
    --color-default-1: var(--color-dark-default-1);
    --color-default-2: var(--color-dark-default-2);

    --color-formelement-bg-1: var(--color-background-5);
    --color-formelement-bg-2: var(--color-background-4);
    --color-formelement-fg: var(--color-foreground-1);
    --color-hover: rgba(217, 217, 217, 0.2);
    --color-success: #279a43;
    --color-warning: #e0ca3c;
    --color-failure: #da5656;

    --color-sidebar-header: #444444;
    --color-sidebar-header-active: var(--color-tertiary-1-trans);
    --color-sidebar-element: #2d2d2d;
    --color-sidebar-fg-hover: rgba(255, 255, 255, 1);
    --color-sidebar-fg: rgba(255, 255, 255, 0.7);

    --color-nav: #3c3c3c;
    --color-nav-shadow: rgba(252, 252, 252, 0.15);
    --color-nav-text: var(--color-foreground-1);
    --color-nav-hover: rgba(239, 91, 91, 0.1);
    --color-nav-active: var(--color-primary-1);
    --color-nav-slider: var(--color-background-1);
    --color-navitem-mobile: var(--color-foreground-1);

    --color-btn-action-danger-bg: #dc758f;

    --color-dialog: var(--color-nav);

    --color-tag: #404040;
    --color-box-shadow: rgba(255, 255, 255, 0.2);
  }

  .theme_light {
    --color-background-transparent-1: rgba(255, 255, 255, 0.1);
    --color-background-transparent-2: rgba(255, 255, 255, 0.2);
    --color-background-transparent-3: rgba(255, 255, 255, 0.3);
    --color-background-transparent-4: rgba(255, 255, 255, 0.4);
    --color-background-transparent-5: rgba(255, 255, 255, 0.5);
    --color-background-transparent-6: rgba(255, 255, 255, 0.6);
    --color-background-transparent-7: rgba(255, 255, 255, 0.7);
    --color-background-transparent-8: rgba(255, 255, 255, 0.8);
    --color-background-transparent-9: rgba(255, 255, 255, 0.9);

    --color-background-1: #e3e5e8;
    --color-background-2: #ebedef;
    --color-background-3: #f2f3f5;
    --color-background-4: #ffffff;
    --color-background-5: #ffffff;
    --color-background-theme: var(--color-light-background-theme);

    --color-foreground-1: #202225;
    --color-foreground-2: #292b2f;
    --color-foreground-3: #2f3136;
    --color-foreground-4: #2f3136;
    --color-foreground-5: #2f3136;

    --color-primary-1: var(--color-light-primary-1);
    --color-primary-2: var(--color-light-primary-2);
    --color-secondary-1: var(--color-light-secondary-1);
    --color-secondary-2: var(--color-light-secondary-2);
    --color-tertiary-1: var(--color-light-tertiary-1);
    --color-tertiary-2: var(--color-light-tertiary-2);
    --color-default-1: var(--color-light-default-1);
    --color-default-2: var(--color-light-default-2);

    --color-hover: rgba(64, 64, 64, 0.2);
    --color-success: #437a34;
    --color-warning: #49a078;
    --color-failure: #8c030e;

    --color-sidebar-header: #e6e6e6;
    --color-sidebar-header-active: var(--color-tertiary-1-trans);
    --color-sidebar-element: var(--color-background-1);
    --color-sidebar-fg-hover: rgba(0, 0, 0, 1);
    --color-sidebar-fg: rgba(0, 0, 0, 0.7);

    --color-nav: #ececec;
    --color-nav-shadow: rgba(0, 0, 0, 0.15);
    --color-nav-text: var(--color-foreground-1);
    --color-nav-hover: rgba(239, 91, 91, 0.1);
    --color-nav-active: var(--color-primary-1);
    --color-nav-slider: var(--color-background-1);
    --color-navitem-mobile: var(--color-foreground-1);

    --color-btn-action-danger-bg: #b33951;

    --color-dialog: var(--color-background-1);

    --color-tag: #e6e6e6;
    --color-box-shadow: rgba(0, 0, 0, 0.3);
  }

  .smooth-page {
    animation: opacity-in 1s cubic-bezier(0, 1, 0.5, 1);
  }

  .app-page {
    height: calc(100vh - var(--height-nav));
    min-height: calc(100vh - var(--height-nav));
    overflow: auto;
    background-color: var(--color-background-theme);
    padding-top: var(--height-nav);
  }

  .app-content {
    margin: 30px;
    background-color: var(--color-background-4);
    border-radius: 6px;
    box-shadow: var(--bs-xl);
    padding: 40px 60px;
    animation: slideRight 0.1s cubic-bezier(0, 1, 0.5, 1);
    @media (max-width: 767px) {
      padding: 10px;
      margin: 15px;
    }
  }

  .app-text {
    margin: auto;
    width: 80%;
    @media (max-width: 767px) {
      width: 100%;
    }
    word-break: break-word;
  }

  .page-title {
    font-size: var(--metric-typography-10);

    // border-left: 10px solid var(--color-primary-1);
    // padding-left: 10px;
    margin-bottom: 20px;
    display: grid;
    row-gap: 6px;

    .page-subtitle {
      font-size: var(--metric-typography-4);
      display: grid;
      grid-auto-flow: row;
      row-gap: 4px;
      justify-content: flex-start;
    }
  }

  .page-highlight {
    margin-top: 10px;
    background-color: var(--color-primary-1);
    height: 8px;
    width: 60px;
    border-radius: 4px;
  }

  .section-highlight {
    margin-top: 6px;
    background-color: var(--color-primary-1);
    height: 6px;
    width: 40px;
    border-radius: 4px;
  }

  .section-close {
    // margin: auto;
    margin-top: 20px;
    background-color: var(--color-foreground-1);
    height: 1px;
    width: 80%;
    border-radius: 4px;
    opacity: 0.2;
  }

  .align-vertical {
    display: flex;
    flex-direction: column;
  }

  .align-horizontal {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: flex-start;
  }

  .section-title {
    font-size: var(--metric-typography-8);
    margin-bottom: 10px;
    margin-top: 10px;
    // border-left: 6px solid var(--color-secondary-1);
    // padding-left: 10px;
    display: grid;
    row-gap: 6px;

    .section-subtitle {
      font-size: var(--metric-typography-4);
      display: grid;
      grid-auto-flow: row;
      row-gap: 4px;
      justify-content: flex-start;
    }

    &.position-right {
      justify-content: flex-end;
      // border-right: 6px solid var(--color-secondary-1);
      // padding-right: 10px;
      // border-left: none;
      // padding-left: none;
      justify-items: flex-end;
    }
  }

  .section-header,
  .page-header {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: space-between;
  }

  .user-form {
    display: grid;
    grid-auto-flow: row;
    row-gap: 20px;
  }

  .hyperlink-container {
    cursor: pointer;
    .material-icons {
      color: var(--color-foreground-3);
    }
    &:hover {
      color: var(--color-primary-1);
      .material-icons {
        color: var(--color-primary-1);
      }
    }
  }

  .hyperlink {
    cursor: pointer;
    user-select: none;
    color: var(--color-primary-1);
    &:hover {
      text-decoration: underline;
    }
  }

  .hyperlink-drama {
    cursor: pointer;
    &:hover {
      color: var(--color-primary-1);
    }
  }

  .action-footer,
  .action-header {
    // height: 60px;
    // display: grid;
    // grid-auto-flow: column;
    // justify-content: center;
    // align-items: center;
    // column-gap: 10px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
    > * {
      margin: 5px 10px;
    }

    &.position-right {
      justify-content: flex-end;
    }

    &.position-left {
      justify-content: flex-start;
    }
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(-30vh);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideUpIn {
  0% {
    transform: translateY(30vh);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes opacity-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
