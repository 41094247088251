.oak-viewer {
  font-size: 16px;

  h1 {
    font-size: 2em !important;
  }
  h2 {
    font-size: 1.5em !important;
  }
  h3 {
    font-size: 1.17em;
  }
  h4 {
    font-size: 1.12em;
  }
  h5 {
    font-size: 0.83em;
  }
  h6 {
    font-size: 0.75em;
  }
  ol,
  ul {
    li {
      list-style-position: inside;
      padding-left: 1.5em;
      display: list-item;
      list-style-type: none;
      &::before {
        display: inline-block;
        // width: 1.2em;
        white-space: nowrap;
        // margin-left: -1.5em;
        margin-right: 0.3em;
        text-align: right;
      }

      &.ql-indent-1 {
        padding-left: 4.5em;
      }

      &.ql-indent-2 {
        padding-left: 7.5em;
      }

      &.ql-indent-3 {
        padding-left: 10.5em;
      }

      &.ql-indent-4 {
        padding-left: 13.5em;
      }

      &.ql-indent-5 {
        padding-left: 16.5em;
      }

      &.ql-indent-6 {
        padding-left: 19.5em;
      }

      &.ql-indent-7 {
        padding-left: 22.5em;
      }
    }
  }

  ol {
    li {
      list-style-type: none;
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
        list-9;
      counter-increment: list-0;
      &::before {
        content: counter(list-0) '. ';
      }
      &.ql-indent-1 {
        counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-1;
        &::before {
          content: counter(list-0) '.' counter(list-1) '. ';
        }
      }
      &.ql-indent-2 {
        counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-2;
        &::before {
          content: counter(list-0) '.' counter(list-1) '.' counter(list-2) '. ';
        }
      }
      &.ql-indent-3 {
        counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-3;
        &::before {
          content: counter(list-0) '.' counter(list-1) '.' counter(list-2) '.'
            counter(list-3) '. ';
        }
      }
      &.ql-indent-4 {
        counter-reset: list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-4;
        &::before {
          content: counter(list-4, lower-roman) '. ';
        }
      }
      &.ql-indent-5 {
        counter-reset: list-6 list-7 list-8 list-9;
        counter-increment: list-5;
        &::before {
          content: counter(list-5, lower-roman) '. ';
        }
      }
      &.ql-indent-6 {
        counter-reset: list-7 list-8 list-9;
        counter-increment: list-6;
        &::before {
          content: counter(list-6, lower-roman) '. ';
        }
      }
      &.ql-indent-7 {
        counter-reset: list-8 list-9;
        counter-increment: list-7;
        &::before {
          content: counter(list-7, lower-roman) '. ';
        }
      }
    }
  }
  ul {
    li {
      list-style-type: none;
      &::before {
        content: '\2022';
      }
    }
  }
  .ql-syntax {
    border-radius: 4px;
    padding: 4px;
    background-color: var(--color-background-transparent-5);
  }

  blockquote {
    background-color: var(--color-background-transparent-5);
  }

  .ql-snow .ql-stroke {
    stroke: var(--color-foreground-3);
  }
  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: var(--color-foreground-3);
  }
  .ql-picker-label {
    color: var(--color-foreground-3);
    &:hover {
      color: green;
    }
  }
  .ql-strike.ql-active {
    // border: 1px solid var(--color-foreground-3);
    background-color: var(--color-foreground-5);
    color: var(--color-foreground-1);
  }
}
